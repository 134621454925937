@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";

:root {
  --background-color: #fdfdfe;
  --blue-700: #0077d3;
  --blue-600: #0077d3;
  --blue-500: #66a3db;
  --selected-button: #d1f5d1;
  --left-bar-selected-item-color: rgba(5, 122, 207, 0.12);
}
